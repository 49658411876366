import { AbsenceType, SchoolAbsenceType } from "./value_object";

/** 學校定義可請假的缺曠類別的資訊  */
export class AbsenceTypeHelper {

    static async getAllowedAbsenceTypes() {
        const resp = await fetch('/service/absence_type/get_allowed_types');
        return await resp.json();
    }

    /**取得學校缺曠類別 */
    static async getSchoolAbsenceType(dsns:string): Promise<SchoolAbsenceType[]> {
    const resp = await fetch('/service/absence_type/get_school_absence_type?schooldsns='+dsns);
    const result: SchoolAbsenceType[] | [] = await resp.json()
    return await result;
    }

    /**更新學校缺曠類別 */
    static async updateSchoolAbsenceType(semester:number,absence_types:AbsenceType[]) {
        const data = {
            "semester": semester,
            "absence_types": absence_types
        }
        const resp = await fetch('/service/absence_type/update_school_absence_type', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

    /**新增學校缺曠類別 */
    static async addSchoolAbsenceType(semester:number,absence_types:AbsenceType[]) {
        const data = {
            "semester": semester,
            "absence_types": absence_types
        }
        /*const resp = await fetch('/service/absence_type/insert_school_absence_type', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();*/
    }
    /** 刪除學校缺曠類別 */
    static async deleteSchoolAbsenceType(semester:number) {
        const data = {
            "semester": semester
        }
        const resp = await fetch('/service/absence_type/delete_school_absence_type', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        return await resp.json();
    }

}